.Hero{
    margin: 0 5rem 0;
    margin-top: 8rem;
    
    
}
.hero-text{
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
    color: white;
}
.hero-text>span:nth-of-type(3){
    font-size: 1rem;
}
.hero-text>span:nth-of-type(4){
    font-size: 1rem;
}
@media only screen and (max-width: 400px){
.hero-buttons{
    display: flex;
    flex-direction: column;
}

}