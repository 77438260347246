.contact-us{
    display: flex;
    padding:0 3rem;
  padding-top: 15rem;
    
   gap: 9rem;
  
    
    
    color: white;
    
}
.spacing{
    display: flex;
    flex-direction: column;
  
    gap: 0.5rem;
    text-align: start;
    color: rgb(201, 200, 200);
}
.spacing>span:nth-of-type(1){
    margin-bottom: 0.5rem;
    color: white;
}
.spacing>span:hover{
    cursor: pointer;
    color: white;
}
.email-container{
    display: flex;
   
    /* background-color: gray; */
   
    gap: 1rem;
    margin-top: 0.5rem;

}
.email-container>input{
    background: transparent;
    border-radius: 12px;
    color: white;
    width: 15rem;
    
    border: 2px solid white;
}
.email-container>input:focus{
    outline: none;
}

  .email-container>button{
    
 
   
    width: 5rem;
  }
  @media only screen and (max-width: 600px){
    .contact-us{
        flex-direction: column;
        gap: 2rem;
     
        text-align: center;
    }
    .spacing{
        text-align: center;
    }
    .spacing>span:nth-of-type(1){
        margin-bottom: 0rem;
        color: white;
    }
  }