.navbar{
   padding: 0.3rem 9rem ;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
   
    color: white;


    
}
.navbar-dark{
 background-color:rgb(10, 10, 27); 
}
.navbar-light{
    background-color:rgb(29, 38, 48)
}
.logo{
    margin-right: 22rem;
}
.signin{
    margin-left: 5rem;
}

.nav-align{
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    /* //margin-top: 10px; */
    font-size: 20px;
}
.nav-item:hover{
cursor: pointer;
color: dodgerblue;
}
.nav-link:hover{
color: dodgerblue;
}
.getStart>li:nth-of-type(1):hover{
    color: dodgerblue;
}

.logo{
    font-size: 2rem;
    font-weight: bold;
}
.logo>span:nth-of-type(1){
    color: dodgerblue;
}
.hero-buttons{
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    gap: 2rem;
}
.hero-buttons>.btn:nth-of-type(2){
    background-color: white;

}
@media only screen and (max-width: 600px){
    .navbar{
        padding: 0;
        display: flex;
        justify-content: space-between;
    }
    .logo{
        margin-right: 0;
    }
    
}