.services{
    margin-top: 5rem;
    background-color: white;
    color: black;
    margin-bottom: 5rem;

}
.services-txt{
  
    display: flex;
    flex-direction: column;

}
.services-txt>span:nth-of-type(1){
    font-size: 3rem;
    font-weight: bold;

}
.box-wrap{
    display: flex;
    flex-direction: row;
    gap: 2rem;
   justify-content: center;
   margin: 3rem 2rem;

    
}
.box{
   height: 300px;
   width: 300px;
   display: flex;
   flex-direction: column;
   gap: 1rem;
   
   background-color: white;
   border-radius: 10px;
   padding-top: 30px;
   margin-bottom: 20px;
}
.box:hover{
    background-color: rgb(24, 24, 62);;
    cursor: pointer;
    color: white;
    transition: 0.5s;
    transform: scale(1.1);
}
.box>span:nth-of-type(1){
    
    font-weight: bold;
    font-size: 2rem;
}
.box>span:nth-of-type(2)>li{
    list-style-type: none;

}
.list{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
@media only screen and (max-width: 600px){
    .box-wrap{
        display: flex;
        flex-direction: column;
        gap: 1rem;
       justify-content: center;
       
    }
}
