.why-us{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.top-txt{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: white;
}
.top-txt>span:nth-of-type(1){
    font-size: 3rem;
    font-weight: bold;
   
}

.top-txt>span:nth-of-type(2){
    font-size: 1.5rem;
   
   
}
.card-container{
    display: flex;
    gap: 1.5rem;
    margin:0 2rem 2rem;


}
.card{
    height: 400px;
    width: 300px;
    background-color: white;
    border-radius: 12px;
    background-color: transparent;
   
    border: 1px solid #f1f1f1;
    perspective: 1000px;
}
.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .card:hover .card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
    border-radius: 12px;
  }
  
  /* Style the front side (fallback if image is missing) */
  .card-front {
 background-color: white;
 /* width: 300px; */
 height: 400px;
 


 display: flex;
 flex-direction: column;
 gap: 1rem;
 justify-content: center;
 align-items: center;
  }
  .card-front>img{
    height: 50px;
    width: 50px;
  }
  
  /* Style the back side */
  .card-back {
   text-align: center;
   
    color: black;
    background-color: white;
    transform: rotateY(180deg);
  }
  .card-back>p{
    display: block;
    margin-top: 7rem;
    text-align: center;
  }
  @media only screen and (max-width: 600px){
    .card-container{
      display: flex;
      flex-direction: column;
      gap: 1rem;
     
  
  
  }
  }
